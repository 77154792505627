@tailwind base;
@tailwind components;
@tailwind utilities;

.output {
  color: white;
}

#btnRun {
  top: 45%;
  transform: translateY(-50%);
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
}

.editor-box,
.editor-box:active,
.editor-box:focus,
.editor-box::selection,
.cm-editor,
.cm-focused {
  border-radius: 4px;
  outline: none !important;
}

.cm-activeLine {
  padding: 0 2px 0 12px !important;
}

.cm-content {
  background-color: white;
}
.cm-lineNumbers {
  color: rgb(71, 85, 105);
}
